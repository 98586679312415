import React, {SetStateAction, useEffect, useRef, useState} from "react";
import SearchQueryOptions from "../organisms/SearchQueryOptions";
import {SearchResultsDisplay} from "../organisms/SearchResultsDisplay";
import {convertJsonObjectToSearchResponse, SearchResponse} from "../data/SearchResponse";
import SearchInputForm from "../molecules/SearchInputForm";
import {
    convertJsonObjectToGoogleDorkTemplates,
    GoogleDork,
    GoogleDorkTemplate,
    GoogleDorkTemplates
} from "../data/GoogleDork";
import {doDorkSearch} from "../controller/GoogleDorkController";
import {Alert, Container, LinearProgress} from "@mui/material";
import {Auth} from "aws-amplify";
import Box from "@mui/material/Box";
import {SaveQueryDialog} from "../organisms/SaveQueryDialog";
import {saveQuery} from "../service/SavedQueryService";
import {v4 as uuidv4} from "uuid";
import {getDorkTemplates} from "../service/DorkService";
import Button from "@mui/material/Button";
import {Clear, Save} from "@mui/icons-material";
import ServiceOfflineAlert from "../molecules/alerts/ServiceOfflineAlert";
import InsufficientCreditsAlert from "../molecules/alerts/InsufficientCreditsAlert";
import {useEffectOnce} from "../utils/UseEffectOnce";
import NoSearchTemplatesSelectedAlert from "../molecules/alerts/NoSearchTemplatesSelectedAlert";
import FeedbackButton from "../molecules/FeedbackButton";

interface HomePageProps {
    selectedDorkTemplateIds: string[]
    setSelectedDorkTemplateIds: React.Dispatch<SetStateAction<string[]>>
    searchValue: string
    setSearchValue: React.Dispatch<SetStateAction<string>>
    editQueryId: string
    setEditQueryId: React.Dispatch<SetStateAction<string>>
    editQueryName: string
    setEditQueryName: React.Dispatch<SetStateAction<string>>
    availableCredits: number
    loadUserCallback: () => void
    searchResponses: SearchResponse[]
    setSearchResponses: React.Dispatch<SetStateAction<SearchResponse[]>>
}

function HomePage(props: HomePageProps) {

    let numberOfSearchesInProgress = useRef(0);

    const [saveQueryDialogOpenState, setSaveQueryDialogOpenState] = useState(false)

    const [savedQueryName, setSavedQueryName] = useState("");

    const [dorkOptions, setDorkOptions] = useState<GoogleDorkTemplate[]>([]);

    const [displayServiceOfflineMessage, setDisplayServiceOfflineMessage] = useState(false);

    const [displayInsufficentCreditsMessage, setDisplayInsufficientCreditsMessage] = useState(false);

    const [displayNoSearchTemplatesSelected, setDisplayNoSearchTemplatesSelected] = useState(false);

    useEffectOnce(() => {
        props.loadUserCallback();
        getSearchOptions();
    });

    useEffect(() => {
        setDisplayInsufficientCreditsMessage(false)
        setDisplayNoSearchTemplatesSelected(false)
    }, [props.selectedDorkTemplateIds])

    const updateSaveQueryDialogOpenState = (open: boolean) => {
        if (open) {
            setSavedQueryName(props.editQueryName)
        }
        setSaveQueryDialogOpenState(true)
    };

    function completeSearch(id: string, googleDork: GoogleDork, jwtToken: string) {

        doDorkSearch(id, googleDork, jwtToken)
            .then((response) => {
                    let searchResponse = convertJsonObjectToSearchResponse(response.data);
                    props.setSearchResponses(searchResponses => [...searchResponses,
                            searchResponse
                        ]
                    );
                }
            )
            .catch((error) => {
                if (error.response.status === 402) {
                    setDisplayServiceOfflineMessage(true);
                }
                // Error
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the
                    // browser and an instance of
                    // http.ClientRequest in node.js
                    // console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            }).finally(() => {
            numberOfSearchesInProgress.current = numberOfSearchesInProgress.current - 1;
            props.loadUserCallback();
        });
    }

    const doSearch = () => {
        if (props.selectedDorkTemplateIds.length === 0) {
            setDisplayNoSearchTemplatesSelected(true)
            return
        }
        if (props.selectedDorkTemplateIds.length > props.availableCredits) {
            setDisplayInsufficientCreditsMessage(true)
            return
        }
        numberOfSearchesInProgress.current = props.selectedDorkTemplateIds.length;
        props.setSearchResponses([]);
        const searchRequestId = uuidv4();
        props.selectedDorkTemplateIds.forEach((dorkTemplateId) => {
            let dorkTemplateIndex = dorkOptions.findIndex(option => option.id === dorkTemplateId);
            let googleDork: GoogleDork = {
                googleDorkTemplate: dorkOptions[dorkTemplateIndex],
                searchValue: props.searchValue
            };

            Auth.currentSession().then((session) => {
                completeSearch(searchRequestId, googleDork, session.getAccessToken().getJwtToken());
            })
        })
    }

    const submitSaveQuery = (overwrite: boolean) => {

        let savedQuery = {
            id: overwrite ? props.editQueryId : uuidv4(),
            displayName: savedQueryName,
            data: {
                lastUpdated: new Date(),
                searchEntry: props.searchValue,
                description: "",
                selectedQueryOptionIds: props.selectedDorkTemplateIds
            }
        }

        Auth.currentSession().then((session) => {
            saveQuery(savedQuery, session.getAccessToken().getJwtToken()).then(_ => console.log("Query saved"))
        }).catch((err) => {
                console.log("Unable to save query", err)
                return;
            }
        );
        props.setEditQueryId(savedQuery.id);
        props.setEditQueryName(savedQuery.displayName)
        setSaveQueryDialogOpenState(false);
    };

    function loadTemplates(jwtToken: string) {
        getDorkTemplates(jwtToken)
            .then((response) => {
                let data: GoogleDorkTemplates = convertJsonObjectToGoogleDorkTemplates(response.data);
                setDorkOptions(data.googleDorkTemplates)
            })
            .catch((error) => {
                // Error
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the
                    // browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
    }

    const getSearchOptions = () => {
        console.log("Loading search templates")
        Auth.currentSession().then((session) => {
            loadTemplates(session.getAccessToken().getJwtToken())
        });
    }

    return (<Container sx={{'paddingTop': 20, 'paddingLeft': 0}}>
            {props.editQueryName !== "" &&
                <Container sx={{display: "flex !important", justifyContent: "right !important"}}>
                    <LoadedQuery queryName={props.editQueryName} setEditQueryId={props.setEditQueryId}
                                 setEditQueryName={props.setEditQueryName}/>
                </Container>
            }
            {props.searchValue !== "" &&
                <Container sx={{display: "flex !important", justifyContent: "right !important"}}>
                    <Button variant="contained" endIcon={<Save/>}
                            onClick={() => updateSaveQueryDialogOpenState(true)}>Save</Button>
                </Container>
            }
            <Container maxWidth='sm'>
                <SearchInputForm searchValue={props.searchValue} setSearchValue={props.setSearchValue}
                                 searchCallback={doSearch}/>
            </Container>
            {displayServiceOfflineMessage && <ServiceOfflineAlert/>}
            {!displayServiceOfflineMessage && displayInsufficentCreditsMessage && <InsufficientCreditsAlert/>}
            {displayNoSearchTemplatesSelected && <NoSearchTemplatesSelectedAlert/>}
            <Container maxWidth='sm' sx={{
                'paddingTop': 2, visibility: "hidden", ...(props.selectedDorkTemplateIds.length > 0 && {
                    visibility: "visible"
                }),
            }}>
                <Alert severity="info">This search will
                    consume {props.selectedDorkTemplateIds.length} search credits.</Alert>
            </Container>
            <Container sx={{'paddingTop': 2,}}>
                <SearchQueryOptions selectedDorkTemplateIds={props.selectedDorkTemplateIds}
                                    setSelectedDorkTemplateIds={props.setSelectedDorkTemplateIds}
                                    dorkOptions={dorkOptions}/>
            </Container>
            {Array.from({length: numberOfSearchesInProgress.current > 0 ? 1 : 0}).map((_, index) =>
                <Container key={index} sx={{'paddingTop': 5,}}>
                    <LoadingIcon remainingSearches={numberOfSearchesInProgress.current}/>
                </Container>)
            }
            <SearchResultsDisplay searchResponses={props.searchResponses}/>
            <SaveQueryDialog dialogOpenState={saveQueryDialogOpenState} setDialogOpenState={setSaveQueryDialogOpenState}
                             saveQueryCallback={submitSaveQuery} savedQueryName={savedQueryName}
                             setSavedQueryName={setSavedQueryName}
                             editQueryId={props.editQueryId}/>
            <FeedbackButton/>
        </Container>
    );
}

interface LoadingIconProps {
    remainingSearches: number

}

function LoadingIcon(props: LoadingIconProps) {
    return <Box sx={{width: '80%', paddingTop: 5}}>
        <LinearProgress/>
        {props.remainingSearches} {props.remainingSearches === 1 ? "search" : "searches"} remaining
    </Box>
}

interface LoadedQueryProps {
    queryName: string
    setEditQueryName: React.Dispatch<SetStateAction<string>>
    setEditQueryId: React.Dispatch<SetStateAction<string>>
}

function LoadedQuery(props: LoadedQueryProps) {
    const handleClear = () => {
        props.setEditQueryName("")
        props.setEditQueryId("")
    }
    return <>
        Editing query: {props.queryName}
        <Button color={"primary"} sx={{padding: "0px", margin: "10px", minWidth: "0px"}} onClick={handleClear}><Clear/></Button>
    </>
}

export default HomePage