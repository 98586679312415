import Button from "@mui/material/Button";
import * as React from "react";
import {useAuthenticator} from "@aws-amplify/ui-react";
import {useNavigate} from "react-router-dom";

export default function SignOutButton() {
    const auth = useAuthenticator();
    const navigate = useNavigate();

    function signOut() {
        return () => {
            console.log("Signing out current user")
            auth.signOut();
            navigate("/")
        }
    }

    return <Button onClick={signOut()} color="inherit">Sign Out</Button>
}