import {List} from "@mui/material";
import React from "react";
import {AccountBalanceWallet, Home, Star} from "@mui/icons-material";
import NavItem from "./NavItem";


export default function NavItems() {
    return (
        <List>
            <NavItem text={"Dashboard"} index={1} goto={"/home"} icon={Home}/>
            <NavItem text={"Saved Queries"} index={2} goto={"/home/saved-queries"} icon={Star}/>
            <NavItem text={"Top Up"} index={3} goto={"/home/credits"} icon={AccountBalanceWallet}/>
        </List>
    );
}