import React from "react";
import {
    GoogleDorkTemplate,
} from "../data/GoogleDork";
import Box from "@mui/material/Box";
import {
    Checkbox,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from "@mui/material";

interface SearchQueryOptionsProps {
    selectedDorkTemplateIds: string[]
    setSelectedDorkTemplateIds: React.Dispatch<React.SetStateAction<string[]>>,
    dorkOptions: GoogleDorkTemplate[]
}

function SearchQueryOptions(props: SearchQueryOptionsProps) {

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = props.dorkOptions.map((n) => n.id);
            props.setSelectedDorkTemplateIds(newSelected);
            return;
        }
        props.setSelectedDorkTemplateIds([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
        const selectedIndex = props.selectedDorkTemplateIds.indexOf(id);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(props.selectedDorkTemplateIds, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(props.selectedDorkTemplateIds.slice(1));
        } else if (selectedIndex === props.selectedDorkTemplateIds.length - 1) {
            newSelected = newSelected.concat(props.selectedDorkTemplateIds.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                props.selectedDorkTemplateIds.slice(0, selectedIndex),
                props.selectedDorkTemplateIds.slice(selectedIndex + 1),
            );
        }

        props.setSelectedDorkTemplateIds(newSelected);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (id: string) => props.selectedDorkTemplateIds.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.selectedDorkTemplateIds.length) : 0;

    return (
        <Paper sx={{width: '100%', mb: 1}}>
            <TableContainer>
                <Table
                    aria-labelledby="tableTitle"
                >
                    <EnhancedTableHead
                        onSelectAllClick={handleSelectAllClick}
                        numSelected={props.selectedDorkTemplateIds.length}
                        rowCount={props.dorkOptions.length}
                    />
                    <TableBody>
                        {props.dorkOptions.map((row, index) => {
                            const isItemSelected = isSelected(row.id);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                                <TableRow
                                    hover
                                    onClick={(event) => handleClick(event, row.id)}
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row.id}
                                    selected={isItemSelected}
                                    sx={{cursor: 'pointer'}}
                                >
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            color="primary"
                                            checked={isItemSelected}
                                            inputProps={{
                                                'aria-labelledby': labelId,
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell
                                        component="th"
                                        id={labelId}
                                        scope="row"
                                        padding="none"
                                    >
                                        {row.name}
                                    </TableCell>
                                    <TableCell align="left">{row.category}</TableCell>
                                </TableRow>
                            );
                        })}
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: (53) * emptyRows,
                                }}
                            >
                                <TableCell colSpan={6}/>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={props.dorkOptions.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}


interface EnhancedTableProps {
    numSelected: number;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
    rowCount: number;
}


function EnhancedTableHead(props: EnhancedTableProps) {
    const {onSelectAllClick, numSelected, rowCount} = props;

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'left'}
                        padding={'normal'}
                    >
                        <Box sx={{fontWeight: 'bold'}}>
                            {headCell.label}
                        </Box>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}


interface HeadCell {
    id: string;
    label: string;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'name',
        label: 'Enhanced Search',
    },
    {
        id: 'category',
        label: 'Category',
    },
]


export default SearchQueryOptions