import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import React, {useState} from "react";
import {StyledTableCell} from "./SearchResultsDisplay";
import Button from "@mui/material/Button";
import {Auth} from "aws-amplify";
import {removeIgnoredResult} from "../service/IgnoredSearchResultService";

interface IgnoredResultUrlsDisplayProps {
    searchRequestHash: string
    ignoredResultUrls: string[]
}

export default function IgnoredResultUrlsDisplay(props: IgnoredResultUrlsDisplayProps) {

    const [ignoredResultUrls, setIgnoredResultUrls] = useState(props.ignoredResultUrls)

    function handleIgnoredResultUrlClear(requestUrl: string)
    {
        Auth.currentSession().then(session => {
            removeIgnoredResult(session.getAccessToken().getJwtToken(), props.searchRequestHash, requestUrl).then(response =>
            {
                if (response.status === 200)
                {
                    setIgnoredResultUrls(ignoredResultUrls.filter(url => url !== requestUrl))
                }
            });
        })
    }

    return <TableContainer component={Paper}>
        <Table sx={{minWidth: 150}} aria-label="simple table">
            <TableHead>
                <TableRow>
                    <StyledTableCell width="30%">Ignored URLs</StyledTableCell>
                    <StyledTableCell width="30%">Action</StyledTableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {ignoredResultUrls.map((ignoredResultUrl, index) => (
                    <TableRow
                        key={index}
                        sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                        <TableCell component="th" scope="row" width="30%">
                            {ignoredResultUrl}
                        </TableCell>
                        <TableCell component="th" scope="row" width="30%" align={"center"}>
                            <Button color="secondary" variant={"contained"} onClick={() => handleIgnoredResultUrlClear(ignoredResultUrl)}>Clear</Button>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
}