import Box from "@mui/material/Box";
import {CheckCircle} from "@mui/icons-material";
import React from "react";
import Typography from "@mui/material/Typography";

interface FeatureEntryProps {
    featureText: string
}

export default function FeatureEntry(props: FeatureEntryProps) {

    return <Box sx={{
        display: 'flex',
        flexDirection: 'row',
    }}>
        <CheckCircle sx={{color: "green"}}/>
        <Typography variant={"body2"}>
            {props.featureText}
        </Typography>
    </Box>
}