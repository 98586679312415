import {convertJsonObjectToSearchRequest, SearchRequest} from "./SearchRequest";

export interface SearchResultSummary {
    resultIds: string[]
    searchRequest: SearchRequest
    searchCompleteTimestamp: string
}

export function convertJsonObjectToSearchResultSummary(json: any): SearchResultSummary {
    return {
        resultIds: json.resultIds ?? [],
        searchRequest: convertJsonObjectToSearchRequest(json.searchRequest),
        searchCompleteTimestamp: json.searchCompleteTimestamp ? new Date(json.searchCompleteTimestamp * 1000).toLocaleString() : "",
    };
}

export function convertJsonObjectToSearchResultSummaries(json: any): SearchResultSummary[] {
    if (!json || !json.searchResultSummaries) {
        return [];
    }

    return json.searchResultSummaries.map((searchResultSummary: any) => convertJsonObjectToSearchResultSummary(searchResultSummary));
}