import React from 'react';
import './App.css';
import {Amplify} from 'aws-amplify';

import '@aws-amplify/ui-react/styles.css';

import {Authenticator} from '@aws-amplify/ui-react';

import awsExports from './aws-exports';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import SignInPage from "./pages/SignInPage";
import UserLandingPage from "./pages/UserLandingPage";
import {createTheme, ThemeProvider} from "@mui/material";
import PublicLandingPage from "./pages/PublicLandingPage";
import ProtectedRoute from "./utils/ProtectedRoute";

Amplify.configure(awsExports);

const theme = createTheme({
    palette: {
        primary: {
            main: '#122e4f',
            contrastText: '#fff',
        },
        secondary: {
            main: '#1769aa',
            contrastText: '#fff',
        }
    },
});

const App: React.FC = () => {
    return (<ThemeProvider theme={theme}>
            <Authenticator.Provider>
                <AuthenticatedApp/>
            </Authenticator.Provider>
        </ThemeProvider>
    );
}

const AuthenticatedApp: React.FC = () => {

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<PublicLandingPage/>}/>
                <Route path="/home/*" element={
                    <ProtectedRoute>
                        <UserLandingPage/>
                        <></>
                    </ProtectedRoute>
                }/>
                <Route path="/sign_in" element={<SignInPage/>}/>
            </Routes>
        </BrowserRouter>
    );
}
export default App
