import {Container, Paper} from "@mui/material";
import PublicTitleAppBar from "../organisms/PublicTitleAppBar";
import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Search, Star} from "@mui/icons-material";
import PricingTable from "../organisms/PricingTable";

export default function PublicLandingPage() {

    return <>
        <PublicTitleAppBar/>
        <Container sx={{
            margin: "0px",
            height: "100vh",
            padding: "0px",
            maxWidth: "100% !important",
            maxHeight: "100% !important"
        }}>
            <Box sx={{
                display: 'flex', flexDirection: 'column', width: '100%'
            }}>
                <Box sx={{
                    display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center'
                }}>
                    <Box sx={{
                        m: 3, p: 3,
                        display: 'flex', flexDirection: 'column', maxWidth: "50%",
                        justifyContent: "center"
                    }}>
                        <Paper elevation={12}>
                            <Box sx={{
                                m: 5,
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: "center",
                                justifyContent: "center"
                            }}>
                                <Search sx={{m: 1, height: "40px", width: "40px"}}></Search>
                                <Typography variant="subtitle1">
                                    Refine your searches with advanced filters to find exactly what you need.
                                </Typography>
                            </Box>
                        </Paper>
                    </Box>
                    <Box sx={{
                        m: 3, p: 3,
                        display: 'flex',
                        maxWidth: "50%",
                        justifyContent: "center"
                    }}>
                        <Paper elevation={12}>
                            <Box sx={{
                                m: 5,
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: "center",
                                justifyContent: "center"
                            }}>
                                <Star sx={{m: 1, height: "40px", width: "40px"}}></Star>
                                <Typography variant="subtitle1">
                                    Save your favourite queries to reuse them at a later date.
                                </Typography>
                            </Box>
                        </Paper>
                    </Box>
                </Box>
                <Box sx={{
                    display: 'flex',
                    justifyContent: "center"
                }}>
                    <PricingTable userLoggedIn={false}/>
                </Box>
            </Box>

        </Container>
    </>
}