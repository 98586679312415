import {Box, Container} from "@mui/material";
import PricingTable from "../organisms/PricingTable";

export default function TopUpPage() {
    return <Container sx={{paddingTop: 20}}>
        <h2>Top up your credits</h2>

        <Box sx={{
            display: 'flex',
            justifyContent: "center"
        }}>
            <PricingTable userLoggedIn={true}/>
        </Box>
    </Container>
}