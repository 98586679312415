import React, {useState} from "react";
import {SearchResponse, SearchResult} from "../data/SearchResponse";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary, Container, Link, Paper, styled,
    Table, TableBody,
    TableCell, tableCellClasses,
    TableContainer,
    TableHead, TableRow, Tooltip
} from "@mui/material";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from "@mui/material/Button";
import {addIgnoredResult, removeIgnoredResult} from "../service/IgnoredSearchResultService";
import {Auth} from "aws-amplify";
import Box from "@mui/material/Box";
import IgnoredResultUrlsDisplay from "./IgnoredResultsDisplay";

export class SearchResultsDisplay extends React.Component<{ searchResponses: SearchResponse[] }> {
    render() {
        let {searchResponses} = this.props;
        return searchResponses.map((searchResponse, index) => (
                <SearchResultSetDisplay key={index} searchResponse={searchResponse}></SearchResultSetDisplay>
            )
        )
    }
}

interface SearchResultSetDisplayProps {
    searchResponse: SearchResponse
}

function SearchResultSetDisplay(props: SearchResultSetDisplayProps) {

    const [showIgnoredUrls, setShowIgnoredUrls] = useState(false);

    function generateSearchResponseAccordionDetail(searchResponse: SearchResponse) {
        return <AccordionDetails><SearchResultDisplay searchResults={searchResponse.osintResults}
                                                      hashedSearchRequest={searchResponse.searchRequestHash}></SearchResultDisplay></AccordionDetails>
    }

    function generateIgnoredUrlsAccordionDetail(searchResponse: SearchResponse) {
        return <AccordionDetails><IgnoredResultUrlsDisplay
            searchRequestHash={searchResponse.searchRequestHash}
            ignoredResultUrls={searchResponse.ignoredOsintUrls}/></AccordionDetails>
    }

    function handleShowIgnoredUrlsClick(event: React.MouseEvent) {
        event.stopPropagation();
        setShowIgnoredUrls(!showIgnoredUrls);
    }

    return <Container sx={{paddingTop: 3}}>
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <Box sx={{width: "100%", display: "flex", justifyContent: "space-between"}}>
                    <Typography>{props.searchResponse.googleDorkTemplate.name} - {props.searchResponse.osintResults.length} results
                        found
                    </Typography>
                    <Button variant="text" sx={{zIndex: 1}}
                            onClick={(event) => handleShowIgnoredUrlsClick(event)}>
                        {showIgnoredUrls ? props.searchResponse.osintResults.length + " results" : props.searchResponse.ignoredOsintUrls.length + " hidden"}
                    </Button>
                </Box>

            </AccordionSummary>
            {showIgnoredUrls ? generateIgnoredUrlsAccordionDetail(props.searchResponse) : generateSearchResponseAccordionDetail(props.searchResponse)}
        </Accordion>
    </Container>
}

export const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        color: theme.palette.common.black,
        fontSize: 18,
        textAlign: "center"
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

interface SearchResultDisplayProps {
    searchResults: SearchResult[]
    hashedSearchRequest: string
}

function SearchResultDisplay(props: SearchResultDisplayProps) {

    const [ignoredUrls, setIgnoredUrls] = useState<string[]>([]);

    function handleIgnoredResultButtonClick(searchResult: SearchResult)
    {
        if (ignoredUrls.findIndex(url => url === searchResult.link) > -1)
        {
            Auth.currentSession().then(session => {
                removeIgnoredResult(session.getAccessToken().getJwtToken(), props.hashedSearchRequest, searchResult.link).then(response =>
                {
                    if (response.status === 200)
                    {
                        setIgnoredUrls(ignoredUrls.filter(url => url !== searchResult.link))
                    }
                });
            })
        }
        else
        {
            doAddIgnoredResult(searchResult);
        }
    }

    function doAddIgnoredResult(searchResult: SearchResult) {
        console.log(props.hashedSearchRequest)
        Auth.currentSession().then((session) => {
                addIgnoredResult(session.getAccessToken().getJwtToken(), searchResult, props.hashedSearchRequest).then(_ => {
                    setIgnoredUrls(ignoredUrls => [...ignoredUrls,
                            searchResult.link
                        ]
                    );
                })
            }
        )
    }

    return <TableContainer component={Paper}>
        <Table sx={{minWidth: 150}} aria-label="simple table">
            <TableHead>
                <TableRow>
                    <StyledTableCell width="30%">Title</StyledTableCell>
                    <StyledTableCell width="30%">Snippet</StyledTableCell>
                    <StyledTableCell width="30%">Link</StyledTableCell>
                    <StyledTableCell width="30%">Action</StyledTableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {props.searchResults.map((searchResult, index) => (
                    <TableRow
                        key={index}
                        sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                        <TableCell component="th" scope="row" width="30%">
                            {searchResult.title}
                        </TableCell>
                        <TableCell width="30%">{searchResult.snippet}</TableCell>
                        <TableCell width="30%" sx={{
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            maxWidth: "1px"
                        }}>
                            <Link href={"https://" + searchResult.link} rel="noreferrer"
                                  target="_blank">{searchResult.link}</Link>
                        </TableCell>
                        <TableCell width="30%">
                            <Tooltip title="This search result will be ignored in future searches">
                                <Button
                                    variant={ignoredUrls.findIndex(url => url === searchResult.link) > -1 ? "text" : "contained"}
                                    color="success"
                                    onClick={() => handleIgnoredResultButtonClick(searchResult)}>{ignoredUrls.findIndex(url => url === searchResult.link) > -1 ? "Acknowledged" : "Acknowledge"}</Button>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
}
