import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import * as React from "react";

function SignInButton() {
    let navigate = useNavigate();

    function signIn() {
        return () => {
            console.log("Signing in user")
            navigate("../sign_in")
        }
    }

    return <Button onClick={signIn()} color="inherit">Sign In</Button>

}

export default SignInButton