import axios, {AxiosResponse} from "axios";
import getApiBaseUrl from "./ServiceConstants";
import {SearchResult} from "../data/SearchResponse";

interface ResultToIgnore {
    searchRequestHash: string
    osintResultUrl: string
}

export function resultToIgnoreToJson(resultToIgnore: ResultToIgnore): string {
    return JSON.stringify({
        search_request_hash: resultToIgnore.searchRequestHash,
        osint_result_url: resultToIgnore.osintResultUrl
    });
}


export async function addIgnoredResult(accessToken: string, searchResult: SearchResult, searchRequestHash: string): Promise<AxiosResponse> {

    let resultToIgnore: ResultToIgnore = {searchRequestHash: searchRequestHash, osintResultUrl: searchResult.link}

    let json: string = resultToIgnoreToJson(resultToIgnore);

    return axios.post(getApiBaseUrl() + '/ignored_search_results', {
            json
        },
        {
            headers: {
                Authorization: accessToken,
            }
        });
}

export async function removeIgnoredResult(accessToken: string, searchRequestHash: string, ignoredUrl: string): Promise<AxiosResponse> {

    return axios.delete(getApiBaseUrl() + '/ignored_search_results?ignoredUrl=' + encodeURIComponent(ignoredUrl) + '&searchRequestHash=' + encodeURIComponent(searchRequestHash),
        {
            headers: {
                Authorization: accessToken,
            }
        });
}