
export interface CheckoutSessionId {
    checkoutSessionId: string
}

export function convertJsonObjectToCheckoutSessionId (json: any) : CheckoutSessionId {
    return {
        checkoutSessionId: json.session_id,
    };
}
