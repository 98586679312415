export interface SearchRequest {
    id: string
    searchEntry: string;
    selectedQueryOptionId: string;
}

export function searchRequestToJson(searchRequest: SearchRequest): string {
    return JSON.stringify({
        id: searchRequest.id,
        search_entry: searchRequest.searchEntry,
        selected_query_option_id: searchRequest.selectedQueryOptionId
    });
}

export function convertJsonObjectToSearchRequest(json: any): SearchRequest {
    return {
        id: json.id,
        searchEntry: json.search_entry,
        selectedQueryOptionId: json.selected_query_option_id,
    };
}