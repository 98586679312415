import React, {useState} from "react";
import {Route, Routes, useNavigate} from "react-router-dom";
import {SavedQuery} from "../data/SavedQuery";
import Box from "@mui/material/Box";
import UserDrawer from "../organisms/UserDrawer";
import HomePage from "./HomePage";
import SavedQueriesDisplay from "../organisms/SavedQueriesDisplay";
import TitleAppBar from "../organisms/TitleAppBar";
import {Auth} from "aws-amplify";
import {getUser} from "../service/UserService";
import {convertJsonObjectToUser} from "../data/User";
import TopUpPage from "./TopUpPage";
import {useEffectOnce} from "../utils/UseEffectOnce";
import {convertJsonObjectToSearchResultSummaries, SearchResultSummary} from "../data/SearchResultSummary";
import {getSearchResultSummaries} from "../service/SearchResultsService";
import {CombinedSearchResponse, SearchResponse} from "../data/SearchResponse";

export default function UserLandingPage() {

    const [selectedDorkTemplateIds, setSelectedDorkTemplateIds] = useState<string[]>([]);
    const [searchValue, setSearchValue] = useState("");
    const [editQueryId, setEditQueryId] = useState("");
    const [editQueryName, setEditQueryName] = useState("");
    const [availableCredits, setAvailableCredits] = useState(-1);
    const [historicalSearchResultSummaries, setHistoricalSearchResultSummaries] = useState<SearchResultSummary[]>([]);
    const [searchResponses, setSearchResponses] = useState<SearchResponse[]>([])

    let navigate = useNavigate();

    useEffectOnce(() => {
        loadPreviousSearchResultSummaries();
    });


    function loadPreviousSearchResultSummaries() {
        Auth.currentSession().then((session) => {
            getSearchResultSummaries(session.getAccessToken().getJwtToken()).then((response) => {
                console.log("Retrieving previous search requests")
                let historicalSearchResultSummaries = convertJsonObjectToSearchResultSummaries(response.data);
                setHistoricalSearchResultSummaries(historicalSearchResultSummaries);
            }).catch((error) => {
                // Error
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the
                    // browser and an instance of
                    // http.ClientRequest in node.js
                    // console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            });
        })
    }

    function combinedSearchResponseLoadCallback(combinedSearchResponse: CombinedSearchResponse) {
        console.log("Extracting data from loaded Search Response")
        setSearchValue(combinedSearchResponse.searchResponses[0].searchRequest.searchEntry)
        setSelectedDorkTemplateIds(combinedSearchResponse.searchResponses.flatMap(response => response.searchRequest.selectedQueryOptionId))
        setSearchResponses(combinedSearchResponse.searchResponses)

    }

    function loadSavedQuery(savedQuery: SavedQuery) {
        console.log("Setting search value to " + savedQuery.data.searchEntry)
        setEditQueryId(savedQuery.id)
        setEditQueryName(savedQuery.displayName)
        setSearchValue(savedQuery.data.searchEntry)
        setSelectedDorkTemplateIds(savedQuery.data.selectedQueryOptionIds)
        setSearchResponses([])
        navigate("/home")
    }

    function loadUser() {
        console.log("Loading user")
        Auth.currentSession().then(session => {
            getUser(session.getAccessToken().getJwtToken()).then((response) => {
                setAvailableCredits(convertJsonObjectToUser(response.data).remainingSearches)
            })
                .catch((error) => {
                    // Error
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the
                        // browser and an instance of
                        // http.ClientRequest in node.js
                        console.log(error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                    }
                    console.log(error.config);
                });
        });
    }

    return <>
        <TitleAppBar availableCredits={availableCredits}/>
        <Box sx={{flexGrow: 0}}>
            <Box sx={{display: 'flex'}}>
                <Box>
                    <UserDrawer searchResultSummaries={historicalSearchResultSummaries} combinedSearchResponseLoadCallback={combinedSearchResponseLoadCallback}/>
                </Box>
                <Routes>
                    <Route path="/" element={<HomePage searchValue={searchValue}
                                                       setSearchValue={setSearchValue}
                                                       selectedDorkTemplateIds={selectedDorkTemplateIds}
                                                       setSelectedDorkTemplateIds={setSelectedDorkTemplateIds}
                                                       editQueryId={editQueryId}
                                                       setEditQueryId={setEditQueryId}
                                                       editQueryName={editQueryName}
                                                       setEditQueryName={setEditQueryName}
                                                       availableCredits={availableCredits}
                                                       loadUserCallback={loadUser}
                                                       searchResponses={searchResponses}
                                                       setSearchResponses={setSearchResponses}
                    />}/>
                    <Route path="/saved-queries"
                           element={<SavedQueriesDisplay loadSavedQuery={loadSavedQuery}/>}/>
                    <Route path="/credits"
                           element={<TopUpPage/>}/>
                </Routes>
            </Box>
        </Box>
    </>
}