import Button from "@mui/material/Button";
import React from "react";
import {createCheckoutSession} from "../service/StripeService";
import {convertJsonObjectToCheckoutSessionId} from "../data/CheckoutSessionId";
import {loadStripe} from '@stripe/stripe-js';
import {Auth} from "aws-amplify";

export default function CheckoutButton() {

    function onCheckoutClick() {
        console.log("Checkout requested")
        Auth.currentSession().then((session) => {
            createCheckoutSession(session.getAccessToken().getJwtToken()).then(response => {
                const checkoutSessionId = convertJsonObjectToCheckoutSessionId(response.data)
                redirectToStripeCheckout(checkoutSessionId.checkoutSessionId).then(r => {
                });
            })
        });
    }

    const redirectToStripeCheckout = async (sessionId: string) => {
        const stripe = await loadStripe('pk_live_51NmI9QEmsor8pslmoJSZsd07Fmh6LHJdXsHWsxBB62FOkv8mSFNdWzZ80Wimx5Kxgy2qinUtBc20aCAPJjkQAggW00gOd3C0hX');

        if (stripe == null) {
            console.error("Unable to connect to Stripe")
        }
        // Replace 'your_session_id' with the actual session ID you received from your Lambda function.
        const {error} = await stripe!.redirectToCheckout({
            sessionId: sessionId,
        });

        if (error) {
            console.error(error);
        }
    };


    return <Button color="secondary" variant="contained" onClick={() => onCheckoutClick()}>Checkout</Button>
}