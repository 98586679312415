export interface SavedQueryPayload {
    lastUpdated: Date;
    searchEntry: string;
    description: string,
    selectedQueryOptionIds: string[];
}


export function convertJsonObjectToSavedQueryPayload(json: any): SavedQueryPayload {
    return {
        lastUpdated: json.lastUpdated,
        searchEntry: json.searchEntry,
        description: json.description,
        selectedQueryOptionIds: json.selectedQueryOptionIds
    };
}

export function savedQueryPayloadToJson(payload: SavedQueryPayload): string {
    return JSON.stringify({
        lastUpdated: payload.lastUpdated,
        searchEntry: payload.searchEntry,
        description: payload.description,
        selectedQueryOptionIds: payload.selectedQueryOptionIds
    });
}
