import {SvgIconComponent} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import React from "react";

interface NavItemProps {
    text: string,
    index: number,
    goto: string,
    icon: SvgIconComponent
}

export default function NavItem(navProps: NavItemProps) {
    let navigate = useNavigate();

    const handleClick = (goto: string) => () => {

        navigate(goto);
    }

    return <ListItem key={navProps.text}
                     disablePadding>
        <ListItemButton onClick={handleClick(navProps.goto)}>
            <ListItemIcon>
                <navProps.icon/>
            </ListItemIcon>
            <ListItemText primary={navProps.text}/>
        </ListItemButton>
    </ListItem>
}