import React, {useState} from "react";
import {
    Container,
    Divider,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import {convertJsonObjectToSavedQueries, SavedQuery} from "../data/SavedQuery";
import {deleteSavedQuery, getSavedQueries} from "../service/SavedQueryService";
import {Auth} from "aws-amplify";
import {useEffectOnce} from "../utils/UseEffectOnce";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

interface SavedQueriesDisplayProps {
    loadSavedQuery: (savedQuery: SavedQuery) => void
}

export default function SavedQueriesDisplay(props: SavedQueriesDisplayProps) {
    return <>
        <Container sx={{'paddingTop': 20,}}>
            <h2>
                Saved Queries
            </h2>
            <SavedQueriesTable loadSavedQuery={props.loadSavedQuery}></SavedQueriesTable>
        </Container>
    </>
}

interface SavedQueriesTableProps {
    loadSavedQuery: (savedQuery: SavedQuery) => void
}

function SavedQueriesTable(props: SavedQueriesTableProps) {

    const [savedQueries, setSavedQueries] = useState<SavedQuery[]>([]);
    const [hasLoaded, setHasLoaded] = useState(false);

    const getExistingSavedQueries = () => {
        console.log("Retrieving existing Saved Queries")
        Auth.currentSession().then((session) => {
            getSavedQueries(session.getAccessToken().getJwtToken()).then((response) => {
                let retrievedSavedQueries: SavedQuery[] = convertJsonObjectToSavedQueries(response.data);
                setSavedQueries(retrievedSavedQueries);
            })
                .catch((error) => {
                    // Error
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the
                        // browser and an instance of
                        // http.ClientRequest in node.js
                        console.log(error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                    }
                    console.log(error.config);
                })
        }).catch((err) => {
                return;
            }
        ).finally(() => setHasLoaded(true));

    }

    useEffectOnce(
        () => getExistingSavedQueries()
    )

    function submitSavedQueryDelete(id: string) {
        return () => {
            console.log("Deleting SavedQuery")
            Auth.currentSession().then((session) => {

                deleteSavedQuery(id, session.getAccessToken().getJwtToken()).then(() => {
                    getExistingSavedQueries()
                }).catch((err) => {
                        return;
                    }
                ).finally(() => setHasLoaded(true));

            });
        }
    }

    return (!hasLoaded ? <></> :
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Saved Query</TableCell>
                            <TableCell align="center">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {savedQueries.map((savedQuery) => (
                            <TableRow
                                key={savedQuery.id}
                                sx={{"&:last-child td, &:last-child th": {border: 0}}}
                            >
                                <TableCell component="th" scope="row">
                                    {savedQuery.displayName}
                                </TableCell>
                                <TableCell align={"center"}>
                                    <Stack
                                        direction="row"
                                        justifyContent={"flex-end"}
                                        divider={<Divider orientation="vertical" flexItem/>}
                                        spacing={2}
                                    >
                                        <Box>
                                            <Button variant="contained"
                                                    onClick={() => props.loadSavedQuery(savedQuery)}>Load</Button>
                                        </Box>
                                        <Box>
                                            <Button onClick={submitSavedQueryDelete(savedQuery.id)}
                                                    variant="contained" color="error">Delete</Button>
                                        </Box>
                                    </Stack>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
    );
}
