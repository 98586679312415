import {Paper} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import SignUpButton from "../molecules/SignUpForFreeButton";
import FeatureEntry from "../molecules/pricing/FeatureEntry";
import CheckoutButton from "../molecules/CheckoutButton";

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
        }
    }
}

interface PricingTableProps {
    userLoggedIn: boolean
}

export default function PricingTable(props: PricingTableProps) {
    return <Paper sx={{width: "20rem", color: "white", backgroundColor: "#122e4f"}} elevation={12}>
        <Box sx={{
            m: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: "center"
        }}>
            <Typography variant="h5">
                Pay As You Go
            </Typography>
            <Box sx={{m: 1, alignItems: "center", justifyContent: "center", display: 'flex', flexDirection: 'column'}}>
                <Typography variant="subtitle2">
                    £1 per 10 searches
                </Typography>
            </Box>
            <Box sx={{m: 3, alignItems: "center", justifyContent: "center", display: 'flex', flexDirection: 'column'}}>
                {props.userLoggedIn ? <CheckoutButton/>: <SignUpButton/>}
                <Typography variant="body2">
                    No monthly minimum
                </Typography>
            </Box>
            <Box sx={{m: 1, alignItems: 'left'}}>
                <FeatureEntry featureText={"Enhanced searches"}/>
                <FeatureEntry featureText={"Save your queries"}/>
                <FeatureEntry featureText={"No minimum monthly spend"}/>
            </Box>
        </Box>
    </Paper>

}
