import {Fab} from "@mui/material";
import {Comment} from "@mui/icons-material";
import React from "react";

export default function FeedbackButton() {

    const handleClick = () => {
        console.log("Opening feedback page")
    }

    return <Fab color="primary" data-sleek-feedback onClick={() => handleClick()} sx={{position: "fixed", bottom: 10, right: 10}}
                aria-label="feedbackButton">
        <Comment/>
    </Fab>
}