import axios, {AxiosResponse} from "axios";
import getApiBaseUrl from "./ServiceConstants";

export async function getSearchResultSummaries(accessToken: string): Promise<AxiosResponse> {

    return axios.get(getApiBaseUrl() + '/search_results/summaries', {headers: {Authorization: accessToken}});
}

export async function getSearchResult(accessToken: string, id: string): Promise<AxiosResponse> {

    return axios.get(getApiBaseUrl() + '/search_results/' + id, {headers: {Authorization: accessToken}});
}

export async function getSearchResults(accessToken: string, searchResultIds: string[]): Promise<AxiosResponse> {

    return axios.post(getApiBaseUrl() + '/search_results', {searchResultIds}, {headers: {Authorization: accessToken}});
}