import {GoogleDork} from "../data/GoogleDork";
import {AxiosResponse} from "axios";
import {submitSearch} from "../service/DorkService";
import {SearchRequest} from "../data/SearchRequest";

export async function doDorkSearch(id: string, googleDork: GoogleDork, accessToken: string): Promise<AxiosResponse> {

    return submitSearch(createSearchRequest(id, googleDork), accessToken)
}

function createSearchRequest(id: string, googleDork: GoogleDork): SearchRequest {
    return {
        id: id, searchEntry: googleDork.searchValue, selectedQueryOptionId: googleDork.googleDorkTemplate.id
    }
}
