import React from "react";

import {withAuthenticator} from "@aws-amplify/ui-react";

interface ProtectedRouteProps {
    children: React.ReactNode[]
}

function ProtectedRoute(props: ProtectedRouteProps) {

    return (
        <React.Fragment>
            {
                props.children
            }
        </React.Fragment>
    );
}

export default withAuthenticator(ProtectedRoute);