import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import {styled} from "@mui/material";
import SignInButton from "../molecules/SignInButton";
import * as React from "react";
import Typography from "@mui/material/Typography";

const StyledToolbar = styled(Toolbar)(({theme}) => ({
    alignItems: 'flex-start',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    // Override media queries injected by theme.mixins.toolbar
    '@media all': {
        minHeight: "50vh",
    },
}));

export default function PublicTitleAppBar() {
    return (<Box sx={{flexGrow: 1}}>
            <AppBar position="static" sx={{backgroundColor: "#122e4f"}}>
                <StyledToolbar>
                    <Box sx={{
                        display: 'flex', flexDirection: 'column', width: '100%'
                    }}>
                        <Box sx={{
                            display: 'flex', flexDirection: 'row-reverse', p: 1,
                            m: 1,
                        }}>
                            <Box sx={{p: 1, m: 1,}}>
                                <SignInButton/>
                            </Box>
                        </Box>
                        <Box sx={{
                            display: 'flex', justifyContent: 'center', p: 1,
                            m: 1,
                        }}>
                            <Box sx={{p: 1, m: 1,}}>
                                <Typography
                                    variant="h2"
                                    noWrap
                                    component="div"
                                    sx={{flexGrow: 1, alignSelf: 'center'}}
                                >
                                    ReconOSINT
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={{
                            display: 'flex', justifyContent: 'center', p: 1,
                            m: 1,
                        }}>
                            <Box sx={{p: 1, m: 1,}}>
                                <Typography
                                    variant="h4"
                                    component="div"
                                    sx={{flexGrow: 1, alignSelf: 'center', textAlign:"center"}}
                                >
                                    Uncover valuable information with our advanced OSINT search tool.
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </StyledToolbar>
            </AppBar>
        </Box>
    );
}