import {Container, List} from "@mui/material";
import React from "react";
import {SearchResultSummary} from "../data/SearchResultSummary";
import HistoricalSearchNavItem from "./HistoricalSearchNavItem";
import {CombinedSearchResponse} from "../data/SearchResponse";


interface HistoricalSearchesMenuProps {
    searchResultSummaries: SearchResultSummary[],
    combinedSearchResponseLoadCallback: (combinedSearchResponse: CombinedSearchResponse) => void
}

export default function HistoricalSearchesMenu(props: HistoricalSearchesMenuProps) {

    return (<div>
            <List>
                {props.searchResultSummaries.length !== 0 &&
                    <Container>
                        <h4>Recent Searches</h4>
                    </Container>
                }
                {props.searchResultSummaries.map((searchResultSummary, index) => {
                    return <HistoricalSearchNavItem key={index}
                                                    combinedSearchResponseLoadCallback={props.combinedSearchResponseLoadCallback}
                                                    searchResultSummary={searchResultSummary}/>
                })}
            </List>
        </div>
    );
}