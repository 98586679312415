import {convertJsonObjectToSavedQueryPayload, SavedQueryPayload, savedQueryPayloadToJson} from "./SavedQueryPayload";

export interface SavedQuery {
    id: string
    displayName: string,
    data: SavedQueryPayload
}

export function savedQueryToJson(savedQuery: SavedQuery): string {
    return JSON.stringify({
        id: savedQuery.id,
        displayName: savedQuery.displayName,
        data: savedQueryPayloadToJson(savedQuery.data)
    });
}

export function convertJsonObjectToSavedQuery(json: any): SavedQuery {
    return {
        id: json.id,
        displayName: json.displayName,
        data: convertJsonObjectToSavedQueryPayload(JSON.parse(json.data))
    };
}

export function convertJsonObjectToSavedQueries(json: any): SavedQuery[] {
    if (!json || !json.savedQueries) {
        return [];
    }

    return json.savedQueries.map((savedQuery: any) => convertJsonObjectToSavedQuery(savedQuery));
}