import React, {SetStateAction} from "react";
import {InputAdornment, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import {Search} from "@mui/icons-material";

interface SearchInputFormProps {

    searchValue: string,
    setSearchValue: React.Dispatch<SetStateAction<string>>
    searchCallback: () => void;
}

export default function SearchInputForm(props: SearchInputFormProps) {

    return <TextField
        fullWidth
        id="query-search"
        label="Search Term"
        type="search"
        color="primary"
        variant="outlined"
        value={props.searchValue}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            props.setSearchValue(event.currentTarget.value);
        }}
        onKeyDown={(e) => {
            if (e.key === 'Enter') {
                props.searchCallback()
            }
        }}
        InputProps={{
            endAdornment: (
                <InputAdornment position="end">
                    <Button onClick={() => props.searchCallback()}><Search></Search></Button>
                </InputAdornment>
            ),
        }}
    />
}