import React, {useEffect} from "react";
import {withAuthenticator} from "@aws-amplify/ui-react";
import {useNavigate} from "react-router-dom";

const SignInPage = () => {
    let navigate = useNavigate();

    // Redirect to a different route immediately
    useEffect(() => {
        navigate("/home",{ replace: true })
    }, [navigate]);

    return <div>Sign In</div>;
};

export default withAuthenticator(SignInPage)
