import React, {SetStateAction, useState} from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    TextField
} from "@mui/material";
import Button from "@mui/material/Button";
import OverwiteExistingQueryDialog from "./OverwiteExistingQueryDialog";

interface SaveQueryDialogProps {
    saveQueryCallback: (overwrite: boolean) => void
    savedQueryName: string
    setSavedQueryName: React.Dispatch<SetStateAction<string>>
    dialogOpenState: boolean
    setDialogOpenState: React.Dispatch<SetStateAction<boolean>>
    editQueryId: string
}

export function SaveQueryDialog(props: SaveQueryDialogProps) {
    
    const [existingNameDialogOpen, setExistingNameDialogOpen] = useState(false);

    const handleClose = () => {
        props.setDialogOpenState(false);
    };

    const handleSaveRequest = () => {
        if(props.editQueryId !== "")
        {
            setExistingNameDialogOpen(true)
        }
        else
        {
            props.saveQueryCallback(false)
        }
        props.setDialogOpenState(false);
    }

    return (
        <div>
            <Dialog open={props.dialogOpenState} onClose={handleClose}>
                <DialogContent>
                    <TextField
                        autoFocus
                        fullWidth
                        id="saved-search-name"
                        label="Query Name"
                        margin="dense"
                        type="search"
                        color="primary"
                        variant="standard"
                        value={props.savedQueryName}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            props.setSavedQueryName(event.currentTarget.value)
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSaveRequest}>Save</Button>
                </DialogActions>
            </Dialog>
            <OverwiteExistingQueryDialog dialogOpenState={existingNameDialogOpen} setDialogOpenState={setExistingNameDialogOpen} saveQueryCallback={props.saveQueryCallback}></OverwiteExistingQueryDialog>
        </div>
    );
}