
export interface GoogleDorkTemplates {
    googleDorkTemplates: GoogleDorkTemplate[];
}

export function convertJsonObjectToGoogleDorkTemplates (json: any) : GoogleDorkTemplates {
    return {
        googleDorkTemplates: json.google_dorks,
    };
}

export interface GoogleDorkTemplate {
    id: string;
    category: string;
    name: string;
}

export interface GoogleDork {
    googleDorkTemplate: GoogleDorkTemplate;
    searchValue: string;
}