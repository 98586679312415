import React, {SetStateAction} from "react";
import {
    Dialog,
    DialogActions,
    DialogTitle,
} from "@mui/material";
import Button from "@mui/material/Button";

interface OverwiteExistingQueryDialogProps {
    dialogOpenState: boolean
    setDialogOpenState: React.Dispatch<SetStateAction<boolean>>
    saveQueryCallback: (overwrite: boolean) => void
}

export default function OverwiteExistingQueryDialog(props: OverwiteExistingQueryDialogProps) {

    const handleClose = () => {
        props.setDialogOpenState(false);
    };

    const handleSaveQueryCallback = (overwrite: boolean) => {
        props.saveQueryCallback(overwrite);
        props.setDialogOpenState(false);
    };

    return (
        <div>
            <Dialog open={props.dialogOpenState} onClose={handleClose}>
                <DialogTitle>Editing existing query...</DialogTitle>
                <DialogActions>
                    <Button variant={'outlined'} color={'error'} onClick={handleClose}>Cancel</Button>
                    <Button variant={'outlined'} color={'success'} onClick={() => handleSaveQueryCallback(false)}>Create New</Button>
                    <Button variant={'outlined'} color={'primary'} onClick={() => handleSaveQueryCallback(true)}>Overwrite</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}