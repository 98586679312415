import {GoogleDorkTemplate} from "./GoogleDork";
import {convertJsonObjectToSearchRequest, SearchRequest} from "./SearchRequest";

export interface SearchResponse {
    osintResults: SearchResult[];
    googleDorkTemplate: GoogleDorkTemplate;
    ignoredOsintUrls: string[]
    searchRequestHash: string
    searchRequest: SearchRequest
}

export interface CombinedSearchResponse {
    searchResponses: SearchResponse[];
}

export function convertJsonObjectToCombinedSearchResponse(json: any): CombinedSearchResponse {
    return {
        searchResponses: json.osintResponses.map((osintResponseJson: any) => convertJsonObjectToSearchResponse(osintResponseJson)),
    };
}

export function convertJsonObjectToSearchResponse(json: any): SearchResponse {
    return {
        osintResults: json.osint_results ?? [],
        googleDorkTemplate: json.google_dork,
        ignoredOsintUrls: json.ignored_osint_urls ?? [],
        searchRequestHash: json.search_request_hash ?? "",
        searchRequest: convertJsonObjectToSearchRequest(json.search_request)
    };
}

export function searchResultToJson(searchResult: SearchResult): string {
    return JSON.stringify({
        link: searchResult.link,
        title: searchResult.title,
        snippet: searchResult.snippet
    });
}

export interface SearchResult {
    link: string;
    title: string;
    snippet: string;
}