import {Search} from "@mui/icons-material";
import {ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import React from "react";
import {SearchResultSummary} from "../data/SearchResultSummary";
import {
    CombinedSearchResponse,
    convertJsonObjectToCombinedSearchResponse,
} from "../data/SearchResponse";
import {getSearchResults} from "../service/SearchResultsService";
import {Auth} from "aws-amplify";

interface HistoricalSearchNavItemProps {
    searchResultSummary: SearchResultSummary
    combinedSearchResponseLoadCallback: (combinedSearchResponse: CombinedSearchResponse) => void
}


export default function HistoricalSearchNavItem(props: HistoricalSearchNavItemProps) {
    const handleClick = () => {
        console.log("Loading search results")
        Auth.currentSession().then(session => getSearchResults(session.getAccessToken().getJwtToken(), props.searchResultSummary.resultIds)
            .then(response => {
                let combinedSearchResponses = convertJsonObjectToCombinedSearchResponse(response.data);
                props.combinedSearchResponseLoadCallback(combinedSearchResponses);
                console.log("Search results loaded")
            }));
    }

    function getSearchCompleteTimestamp(summary: SearchResultSummary) {
        const timestamp = props.searchResultSummary.searchCompleteTimestamp;

        return timestamp === "0" ? "" : timestamp;
    }

    return <ListItem key={props.searchResultSummary.resultIds[0]}
                     disablePadding>
        <ListItemButton onClick={handleClick}>
            <ListItemIcon>
                <Search/>
            </ListItemIcon>
            <ListItemText primary={props.searchResultSummary.searchRequest.searchEntry}
                          secondary={getSearchCompleteTimestamp(props.searchResultSummary)}/>
        </ListItemButton>
    </ListItem>
}
