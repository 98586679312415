import axios, {AxiosResponse} from 'axios';
import {SavedQuery, savedQueryToJson} from "../data/SavedQuery";
import getApiBaseUrl from "./ServiceConstants";

export async function getSavedQueries(accessToken: string): Promise<AxiosResponse> {

    return axios.get(getApiBaseUrl() + '/saved_queries', {headers: {Authorization: accessToken}});
}

export async function saveQuery(query: SavedQuery, accessToken: string): Promise<AxiosResponse> {
    let json = savedQueryToJson(query);
    return axios.post(getApiBaseUrl() + '/saved_queries', {
            json
        },
        {
            headers: {
                Authorization: accessToken,
            }
        });
}

export async function deleteSavedQuery(id: string, accessToken: string): Promise<AxiosResponse> {

    return axios.delete(getApiBaseUrl() + '/saved_queries/' + id, {headers: {Authorization: accessToken}});
}