import * as React from "react";
import NavItems from "../molecules/NavItems";
import {Container, Divider, Drawer} from "@mui/material";
import {SearchResultSummary} from "../data/SearchResultSummary";
import HistoricalSearchesMenu from "../molecules/HistoricalSearchesMenu";
import {CombinedSearchResponse} from "../data/SearchResponse";

const drawerWidth = 240;

interface UserDrawerProps {
    searchResultSummaries: SearchResultSummary[]
    combinedSearchResponseLoadCallback: (combinedSearchResponse: CombinedSearchResponse) => void
}

export default function UserDrawer(props: UserDrawerProps) {

    return (
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                },
            }}
            variant="permanent"
            anchor="left">
            <Container sx={{paddingTop: 14}}/>
            <Divider/>
            <NavItems/>
            <Divider/>
            <HistoricalSearchesMenu searchResultSummaries={props.searchResultSummaries} combinedSearchResponseLoadCallback={props.combinedSearchResponseLoadCallback}/>
        </Drawer>
    );
}