import axios, {AxiosResponse} from 'axios';
import {SearchRequest, searchRequestToJson} from "../data/SearchRequest";
import getApiBaseUrl from "./ServiceConstants";

export async function submitSearch(searchRequest: SearchRequest, accessToken: string): Promise<AxiosResponse> {
    let json = searchRequestToJson(searchRequest);
    return axios.post(getApiBaseUrl() + '/osint_query', {
            json
        },
        {
            headers: {
                Authorization: accessToken,
            }
        }
    );
}

export async function getDorkTemplates(accessToken: string): Promise<AxiosResponse> {
    return axios.get(getApiBaseUrl() + '/query_options', {headers: {Authorization: accessToken}});
}
