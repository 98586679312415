import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import SignOutButton from "../molecules/SignOutButton";
import {Button, Container} from "@mui/material";
import {useNavigate} from "react-router-dom";

interface TitleAppBarProps{
    availableCredits: number
}

export default function TitleAppBar(props: TitleAppBarProps) {
    const navigate = useNavigate();

    return (
        <Box sx={{flexGrow: 0}}>
            <AppBar position="fixed" sx={{zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: "#122e4f"}}>
                <Toolbar>
                    <Container maxWidth='sm' sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}>
                        <Box>
                            <Button color="inherit" sx={{
                                fontFamily: "Mulish",
                                fontSize: "4rem",
                                textTransform: 'none',
                                padding: 0,
                            }} onClick={() => navigate("/home")}>ReconOSINT</Button>
                        </Box>
                    </Container>
                    {props.availableCredits > -1 && <Box>Search Credits: {props.availableCredits}</Box>}
                    <SignOutButton/>
                </Toolbar>
            </AppBar>
        </Box>
    );
}