import Button from "@mui/material/Button";
import React from "react";
import {useNavigate} from "react-router-dom";

export default function SignUpButton() {
    let navigate = useNavigate();

    function signUp() {
        return () => {
            navigate("../sign_in")
        }
    }

    return <Button onClick={signUp()} variant="contained" color="secondary" sx={{fontSize: 13}}>Subscribe For Free</Button>

}